<template>
    <v-container fluid class="px-6">
        <!-- Add Purchase Button -->
        <v-btn color="primary" class="my-2" @click="openAddModal">Add Purchase</v-btn>

        <!-- Search Bar and Buttons -->
        <v-card>
            <v-card-title>
                <v-text-field v-model="search" label="Search with Purchase ID" single-line hide-details></v-text-field>
                <v-btn class="mx-2" @click="fetchPurchases">Search</v-btn>
                <v-btn class="mx-2" @click="clearSearch">Clear</v-btn>
            </v-card-title>

            <!-- Purchases Data Table -->
            <v-data-table :headers="headers" :items="formattedPurchases" class="elevation-1"
                :server-items-length="totalPurchases" :options.sync="options" :loading="loading" hide-default-footer>
                <template v-slot:item.purchaseId="{ item }">
                    <span>
                        {{ item.purchaseId }}
                        <v-btn icon @click="copyToClipboard(item.purchaseId)">
                            <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                    </span>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-btn icon @click="editPurchase(item)">
                        <v-icon color="blue">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="deletePurchase(item.purchaseId)">
                        <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>

        <!-- Pagination Controls -->
        <div class="d-flex align-center justify-end my-3" v-if="!loading && purchases.length">
            <v-pagination v-model="options.page" :length="totalPages" :total-visible="5"
                @input="fetchPurchases"></v-pagination>
        </div>

        <!-- Modal Dialog for Adding/Editing Purchase -->
        <v-dialog v-model="showDialog" max-width="600px" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h6">{{ isEditing ? 'Edit Purchase' : 'Add New Purchase' }}</span>
                </v-card-title>

                <v-card-text>
                    <v-form ref="form">
                        <v-text-field v-if="isEditing" v-model="newPurchase.purchaseId" label="Purchase ID"
                            disabled></v-text-field>

                        <v-text-field v-model="newPurchase.vendorName" label="Vendor Name" required></v-text-field>

                        <v-text-field v-model="newPurchase.amount" label="Amount" required></v-text-field>

                        <v-text-field v-model="newPurchase.transactionId" label="Transaction ID"
                            required></v-text-field>

                        <v-select v-model="newPurchase.items" :items="itemOptions" label="Items" multiple chips
                            required></v-select>

                        <v-menu v-model="invoiceMenu" :close-on-content-click="false" transition="scale-transition"
                            offset-y min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="newPurchase.invoiceDate" label="Invoice Date"
                                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                    required></v-text-field>
                            </template>
                            <v-date-picker v-model="newPurchase.invoiceDate"
                                @input="invoiceMenu = false"></v-date-picker>
                        </v-menu>

                        <v-text-field v-model="newPurchase.totalItems" label="Total Items" type="number"
                            required></v-text-field>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showDialog = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="isEditing ? updatePurchase() : addPurchase()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Popup to show purchaseId after saving -->
        <v-dialog v-model="showPurchaseIdDialog" max-width="500px">
            <v-card>
                <v-card-title class="headline">Purchase Created Successfully</v-card-title>
                <v-card-text>
                    <div class="d-flex align-center">
                        <span>Purchase ID: <strong>{{ purchaseId }}</strong></span>
                        <v-btn icon small @click="copyToClipboard(purchaseId)">
                            <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showPurchaseIdDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" timeout="3000" bottom>
            {{ snackbarText }}
        </v-snackbar>
    </v-container>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            ENDPOINT: process.env.VUE_APP_ENDPOINT,
            showDialog: false,
            showPurchaseIdDialog: false,
            purchases: [],
            snackbar: false, // Controls the visibility of the snackbar
            snackbarText: '', // Text to display in the snackbar
            totalPurchases: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['createdOn'], // Default sort by createdOn
                sortDesc: [true], // Sort descending to show the latest entry on top
            },
            loading: false,
            search: '',
            purchaseId: '', // To store the generated purchaseId
            isEditing: false, // Track if we are in edit mode
            itemOptions: [
                'vinyl', 'cd', 'cassette', 'bluray', 'dvd', 'vcd',
                'games', 'audio equipment', 'watches'
            ],
            headers: [
                { text: 'Purchase ID', value: 'purchaseId' },
                { text: 'Vendor Name', value: 'vendorName' },
                { text: 'Amount', value: 'amount' },
                { text: 'Invoice Date', value: 'invoiceDate' },
                { text: 'Total Items', value: 'totalItems' },
                { text: 'Transaction ID', value: 'transactionId' },
                { text: 'Actions', value: 'action', sortable: false },
            ],
            invoiceMenu: false,
            newPurchase: {
                purchaseId: '',
                vendorName: '',
                amount: '',
                invoiceDate: '',
                totalItems: 0,
                transactionId: '',
                items: []
            }
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalPurchases / this.options.itemsPerPage);
        },
        formattedPurchases() {
            return this.purchases.map(purchase => ({
                ...purchase,
                invoiceDate: purchase.invoiceDate
                    ? new Date(purchase.invoiceDate).toLocaleDateString('en-GB')
                    : '',
            }));
        }
    },
    methods: {
        openAddModal() {
            this.isEditing = false; // We are not editing, so set isEditing to false
            this.resetForm(); // Reset the form fields for a new entry
            this.showDialog = true; // Show the modal
        },
        async fetchPurchases() {
            try {
                this.loading = true;
                const { page, itemsPerPage, sortBy, sortDesc } = this.options;

                const response = await axios.get(`${this.ENDPOINT}/purchases`, {
                    params: {
                        page,
                        limit: itemsPerPage,
                        sortBy: sortBy[0] || 'createdOn',
                        sortDesc: sortDesc[0] ? 'desc' : 'asc',
                        search: this.search // Ensure the search parameter is sent correctly
                    }
                });

                this.purchases = response.data.purchases;
                this.totalPurchases = response.data.totalItems;
            } catch (error) {
                console.error("Error fetching purchases:", error);
            } finally {
                this.loading = false;
            }
        },
        clearSearch() {
            this.search = '';
            this.fetchPurchases();
        },
        async addPurchase() {
            try {
                const response = await axios.post(`${this.ENDPOINT}/purchases`, this.newPurchase);
                this.purchaseId = response.data.purchaseId; // Store the generated purchaseId
                this.showPurchaseIdDialog = true; // Show the purchaseId dialog
                this.showDialog = false; // Close the add purchase modal
                this.fetchPurchases(); // Refresh the purchase list
                this.resetForm();
                console.log("purchase id: ", this.purchaseId);
            } catch (error) {
                console.error("Error adding purchase:", error);
            }
        },
        async editPurchase(item) {
            this.isEditing = true;
            this.newPurchase = { ...item };
            this.showDialog = true;
        },
        async updatePurchase() {
            try {
                await axios.put(`${this.ENDPOINT}/purchases/${this.newPurchase.purchaseId}`, this.newPurchase);
                this.showDialog = false;
                this.fetchPurchases();
                this.resetForm();
            } catch (error) {
                console.error("Error updating purchase:", error);
            }
        },
        async deletePurchase(id) {
            try {
                await axios.delete(`${this.ENDPOINT}/purchases/${id}`);
                this.fetchPurchases();
            } catch (error) {
                console.error("Error deleting purchase:", error);
            }
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    this.snackbarText = 'Purchase ID copied to clipboard!';
                    this.snackbar = true;
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                    this.snackbarText = 'Failed to copy Purchase ID';
                    this.snackbar = true;
                });
        },
        resetForm() {
            this.newPurchase = {
                purchaseId: '',
                vendorName: '',
                amount: '',
                invoiceDate: '',
                totalItems: 0,
                transactionId: '',
                items: []
            };
        }
    },
    created() {
        this.fetchPurchases();
    }
};
</script>

<style scoped>
/* Optional styling */
</style>
